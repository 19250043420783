
// Dependencies
import { DictionaryProperty } from "@/assets/scripts/BlockDiagram";
import { defineComponent, PropType } from "vue";
// Components
import CollapseArrow from "@/components/Icons/CollapseArrow.vue";
import DictionaryFieldContents from "./DictionaryFieldContents.vue";

export default defineComponent({
  name: "DictionaryField",
  props: {
    property: {
      type: Object as PropType<DictionaryProperty>,
      required: true
    }
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {

    /**
     * A reactive version of the property.
     * @returns
     *  The property.
     */
    _property(): DictionaryProperty {
      let trigger = this.property.trigger.value;
      return trigger ? this.property : this.property; 
    },

    /**
     * Tests if the property has editable subproperties.
     * @returns
     *  True if the property has editable subproperties, false otherwise.
     */
    hasEditableProperties(): boolean {
      for(let value of this._property.value.values()) {
        if(value.descriptor.is_visible ?? true)
          return true;
      }
      return false;
    }

  },
  emits: ["change", "create", "delete"],
  components: { CollapseArrow, DictionaryFieldContents }
});

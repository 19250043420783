import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e20e14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dictionary-field-control" }
const _hoisted_2 = { class: "dictionary-header" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 0,
  class: "dictionary-contents"
}
const _hoisted_5 = {
  key: 1,
  class: "no-properties"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollapseArrow = _resolveComponent("CollapseArrow")!
  const _component_DictionaryFieldContents = _resolveComponent("DictionaryFieldContents")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['dictionary-header-container', { collapsed: _ctx.collapsed }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed=!_ctx.collapsed))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CollapseArrow, {
          class: "icon",
          collapsed: _ctx.collapsed
        }, null, 8, ["collapsed"]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx._property.toString()), 1)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (!_ctx.collapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.hasEditableProperties)
            ? (_openBlock(), _createBlock(_component_DictionaryFieldContents, {
                key: 0,
                property: _ctx.property,
                onChange: _cache[1] || (_cache[1] = (...args) => _ctx.$emit('change', ...args)),
                onCreate: _cache[2] || (_cache[2] = (...args) => _ctx.$emit('create', ...args)),
                onDelete: _cache[3] || (_cache[3] = (...args) => _ctx.$emit('delete', ...args))
              }, null, 8, ["property"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, "No visible properties."))
        ]))
      : _createCommentVNode("", true)
  ]))
}